import axios, { Axios } from "axios";
import authHeader from '../services/auth-header';

const API_URL = "https://app.ef-tm.com/v1/";
//const API_URL = "http://localhost:3000/v1/";


/* ============== Dashboard Data ===============================*/
const getDashboardData = () => {
  let user = JSON.parse(localStorage.getItem('token'));
  //if (user) {
  return axios
    .get(API_URL + 'admin/dashboard/', { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
  // } else {
  //   return JSON.parse({ status: false, msg: 'Unauthorised User!' });
  // }
};

/*========================== Upload Data =========================*/
const uploaddata = (data) => {
  let user = JSON.parse(localStorage.getItem('token'));
  return axios
    .post(API_URL + 'admin/uploadfile/', data, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${user.accessToken}`,
      }
    })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};


/*========================== Profile Data =======================*/
const getProfileData = () => {
  const id = JSON.parse(localStorage.getItem("user"));

  return axios
    .get(API_URL + 'profiledata/' + id._id, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

const profileupdate = (data) => {
  const id = JSON.parse(localStorage.getItem("user"));

  return axios
    .put(API_URL + 'updateprofile/' + id._id, data, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

//***************************************** Product Area ****************************************** */
// Get all categories
const getAllCategories = (pno = 1, action = '') => {
  var pnostr = pno + '|' + action;
  return axios
    .get(API_URL + 'admin/allcategories/' + pnostr, { headers: authHeader() })
    .then((response) => {
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
}

// search category 
const searchCategoryData = (data) => {
  return axios
    .post(API_URL + 'admin/categorysearch/', data, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};


// get all products
const getAllProducts = (pno = 1, action = '') => {
  var pnostr = pno + '|' + action;
  return axios
    .get(API_URL + 'admin/allproducts/' + pnostr, { headers: authHeader() })
    .then((response) => {
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
}

// Get product by cat id
const getProductByCat = (id) => {
  return axios
    .get(API_URL + 'admin/productbycat/' + id, { headers: authHeader() })
    .then((response) => {
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
}

//Search User by Name
const searchProductData = (data) => {
  return axios
    .post(API_URL + 'admin/productsearch/', data, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

const searchCatProductData = (data) => {
  return axios
    .post(API_URL + 'admin/catproductsearch/', data, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

// Delete Specific Product
const deleteSpecificData = (ids) => {
  return axios
    .post(API_URL + 'admin/delspecificproduct/', ids, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

// Delete Bulk Product
const deleteBulkData = (data) => {
  return axios
    .post(API_URL + 'admin/delbulkproduct/', data, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};
//**************************************** User Area ********************************************** */

const addnewuser = (data) => {
  return axios
    .post(API_URL + 'public/registration/', data, { headers: authHeader() })
    .then((response) => {
      if (response.data.status) {
        return response.data.msg;
      }

      return response.data;
    });
};

//Get all Users
const getAllUserData = () => {
  return axios
    .get(API_URL + 'admin/users/', { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

//Search User by Name
const searchUserData = (data) => {
  return axios
    .post(API_URL + 'admin/usersearch/', data, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

// Get User by ID
const getUserData = (id) => {
  return axios
    .get(API_URL + 'admin/getuser/' + id, { headers: authHeader() })
    .then((response) => {
      //console.log(response.data);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

// Update User
const updateUser = (id, data) => {
  return axios
    .put(API_URL + 'admin/edituser/' + id, data, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data.msg;
      }

      return response.data;
    });
};

// Delete User
const deleteUser = (id) => {
  return axios
    .delete(API_URL + 'admin/deluser/' + id, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};
//************************************************************************************************************ */

//Get all Users
const getAllUserLogData = (pno = 1, action = '') => {
  var pnostr = pno + '|' + action;
  return axios
    .get(API_URL + 'admin/getuserlog/' + pnostr, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

//Search User by Name
const searchUserLogData = (data) => {
  return axios
    .post(API_URL + 'admin/searchuserlog/', data, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

// sort user log data
const sortUserLogData = (data) => {
  return axios
    .post(API_URL + 'admin/sortuserlog/', data, { headers: authHeader() })
    .then((response) => { //console.log(response.data.data[0].email);
      if (response.data.status) {
        return response.data;
      }

      return response.data;
    });
};

/* ========================================= User Log section end ============================================= */

const UserService = {
  //Dashboard Panel
  getDashboardData,

  //Upload Excel
  uploaddata,

  //Profile Panel
  getProfileData,
  profileupdate,

  //user data
  addnewuser,
  getUserData,
  searchUserData,
  getAllUserData,
  updateUser,
  deleteUser,

  // user log
  getAllUserLogData,
  searchUserLogData,
  sortUserLogData,

  // Product Data
  getAllCategories,
  searchCategoryData,
  getAllProducts,
  searchProductData,
  getProductByCat,
  searchCatProductData,
  deleteSpecificData,
  deleteBulkData
}

export default UserService;
import React, { useState, useEffect } from "react";

import adminLayout from "../hoc/adminLayout"
import UserService from "../services/user.service";

const DashboardPage = () => {

  const [dashboardData, setDashboardData] = useState([]);

  const fetchData = () => {
    UserService.getDashboardData().then(
      (response) => {
        console.log(response.data);
        setDashboardData(response.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log(resMessage);
      }
    );
  }

  useEffect(() => {
    fetchData();
  }, []);

  return <>
    <div className="row">
      <div className="col-xl-3 col-sm-6 mb-3">
        <div className="card text-white bg-primary o-hidden h-100">
          <div className="card-body">
            <div className="card-body-icon">
              <i class="fa fa-tag" aria-hidden="true"></i>
            </div>
            <div className="mr-5">{dashboardData.productCount} Products</div>
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-sm-6 mb-3">
        <div className="card text-white bg-warning o-hidden h-100">
          <div className="card-body">
            <div className="card-body-icon">
              <i className="fa fa-user"></i>
            </div>
            <div className="mr-5">{dashboardData.userCount} Users</div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default adminLayout(DashboardPage);
import { createSlice } from "@reduxjs/toolkit";
import { userContants } from "../../constants/ReducerConstants";
export const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    userTokens: {},
    userDetails: {},
  },
  reducers: {
    updateUserTokensReducerFromLocalStorage: (state, action) => {
      state.userTokens = JSON.parse(
        localStorage.getItem(userContants.USER_ACCESS_TOKEN)
      );
    },
    setUserTokens: (state, action) => {
      state.userTokens = action.payload;
      localStorage.setItem(
        userContants.USER_ACCESS_TOKEN,
        JSON.stringify(action.payload)
      );
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      localStorage.setItem(
        userContants.USER_DETAILS,
        JSON.stringify(action.payload)
      );
    },
    clearUserDetails: (state) => {
      state.userDetails = {};
    },
    clearUserTokens: (state) => {
      state.userTokens = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserDetails,
  setUserTokens,
  clearUserDetails,
  clearUserTokens,
  updateUserTokensReducerFromLocalStorage,
} = userSlice.actions;

export default userSlice.reducer;

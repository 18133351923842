import React from "react";
import WebRouter from "./WebRouter";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   setUserTokens,
//   updateUserTokensReducerFromLocalStorage,
// } from "./redux/reducers/userSlice";
// import { userContants } from "./constants/ReducerConstants";
function App() {
  return <WebRouter />;
}

export default App;

import React from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//import UserService from "../src/services/user.service";

import { setUserTokens } from "./redux/reducers/userSlice";
import { userContants } from "./constants/ReducerConstants";
import "font-awesome/css/font-awesome.min.css";
import "./assets/css/app.css";

import DashboardPage from "./pages/DashboardPage";
import ProfilePage from "./pages/profile/ProfilePage";
import LoginPage from "./pages/auth/LoginPage";
import OtpVerification from "./pages/auth/Verification";

import CategoryList from "./pages/products/CategoryList";
import CategoryProductList from "./pages/products/CategoryProductList";
import ProductList from "./pages/products/ListProducts";

import ExcelUpload from "./pages/excelupload/UploadForm";
import UserList from "./pages/userlist/ListUsers";
import NewUser from "./pages/userlist/AddUser";
import UpdateUser from "./pages/userlist/EditUser";

import UserLogs from "./pages/userlist/LogUser";


const WebRouter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   const token = {
  //     accessToken: "42af03d5-ee99-400b-bfb3-c142e1d7139f",
  //     refreshToken: "ec1e1bdc-2622-428d-8a66-55ee04c97e5a",
  //   };
  const getUserDetails = () => {
    const localData = JSON.parse(
      localStorage.getItem(userContants.USER_ACCESS_TOKEN)
    );
    if (localData === undefined || localData === null) {
      console.log("No Data Found");
      navigate("/");
      return;
    }
    dispatch(setUserTokens(localData));
    return localData;
  };
  useEffect(() => {
    // localStorage.setItem(userContants.USER_ACCESS_TOKEN, JSON.stringify(token));
    // call api to check token is valid or not
    //console.log('My WebRoutes');
    getUserDetails();

    /*UserService.getDashboardData().then(
      (data) => {
      },
      (error) => {
        console.log(error);
        if (error.response.status == 401 || error.response.status == 500) {
          //console.log(error);
          navigate("/");
        }
      }
    );*/
  }, []);
  return (
    <Routes>
      <Route exact path="/" element={<LoginPage />} />
      <Route exact path="/otp-verification" element={<OtpVerification />} />
      <Route exact path="/dashboard" element={<DashboardPage />} />
      <Route exact path="/profile" element={<ProfilePage />} />

      <Route exact path="/categories" element={<CategoryList />} />
      <Route exact path="/category/:id" element={<CategoryProductList />} />
      <Route exact path="/products" element={<ProductList />} />

      <Route exact path="/excelupload" element={<ExcelUpload />} />
      <Route exact path="/users" element={<UserList />} />
      <Route exact path="/adduser" element={<NewUser />} />
      <Route exact path="/edituser/:id" element={<UpdateUser />} />

      <Route exact path="/userlog" element={<UserLogs />} />

    </Routes>
  );
};
export default WebRouter;

import axios from "axios";

const API_URL = "https://app.ef-tm.com/v1/public/";
let useremail = '';


const login = (email, password) => {
  return axios
    .post(API_URL + 'login', {
      email,
      password
    })
    .then((response) => {
      console.log(response);

      useremail = response.data.data.user.email;
      console.log(useremail);
      if (useremail) {
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
      }
      if (response.data.data.user.resetToken) {
        localStorage.setItem("token", JSON.stringify(response.data.data.user));
      }

      return response;
    },
      (error) => {
        return error;
      });
};

const refreshtoken = () => {
  const getUser = getCurrentUser();

  if (getUser.email === '') return false;
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  // return axios.post(API_URL + "signout").then((response) => {
  //   return response.data;
  // });
  window.location.href = '/';
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
  refreshtoken
}

export default AuthService;
import React, { useState, useRef } from "react";
import "../../assets/css/login.css"
import { useNavigate } from 'react-router-dom';
import authLayout from "../../hoc/authLayout";
import AuthService from "../../services/auth.service";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

const Verification = () => {   

    const required = (value) => {
        if (!value) {
          return (
            <div className="invalid-feedback d-block">
              This field is required!
            </div>
          );
        }
      };
      
      
        const form = useRef();
        const checkBtn = useRef();
      
        const [otpcode, setOtpCode] = useState("");
        const [email, setEmail] = useState("");
        const [loading, setLoading] = useState(false);
        const [message, setMessage] = useState("");
      
        const navigate = useNavigate();
      
        const onChangeOtpCode = (e) => {
          const otpcode = e.target.value;
          setOtpCode(otpcode);
        };      
      
        const handleVerification = (e) => {
          e.preventDefault();
      
          setMessage("");
          setLoading(true);
      
          form.current.validateAll();
      
          if (checkBtn.current.context._errors.length === 0) {
            const userData = AuthService.getCurrentUser(); //console.log(userData.email);
            setEmail(userData.email);
            AuthService.otpVerification(userData.email,otpcode).then(
              () => {
                navigate("/dashboard");
               // window.location.reload();
              },
              (error) => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
      
                setLoading(false);
                setMessage(resMessage);
              }
            );
          } else {
            setLoading(false);
          }
        };
  
        return <>
            <Form className="login-form" onSubmit={handleVerification} ref={form}>
                <div className="d-flex align-items-center my-4">
                    <h1 className="text-center fw-normal mb-0 me-3">Verification</h1>
                </div>
                {/* <!-- OtpCode input --> */}
                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="otpcodeField">OTP Code</label>
                    <Input 
                    type="otpcode" 
                    id="otpcodeField" 
                    className="form-control form-control-lg"
                    placeholder="Enter a valid OTP" 
                    name="otpcode"
                    value={otpcode}
                    onChange={onChangeOtpCode}
                    validations={[required]}
                    />
                </div>                

                <div className="text-center text-lg-start mt-4 pt-2"> 
                    <button className="btn btn-primary btn-lg" disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Login</span>
                    </button>                  
                </div>
                {message && (
                    <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                    </div>
                )}
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
        </>
}

export default authLayout(Verification);
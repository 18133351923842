import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { format } from 'date-fns';

import adminLayout from "../../hoc/adminLayout";
import UserService from "../../services/user.service";

const ListProducts = () => {

    /* ========================= Product data start =============================== */
    const [productList, setproductList] = useState([]);
    const [searchstr, setsearchstr] = useState([]);
    const [checked, setChecked] = useState([]);
    const [errorMessage, seterrorMessage] = useState(false);
    const [pageno, setpageNo] = useState(1);

    const fetchData = (pno, action = 'none') => {
        UserService.getAllProducts(pno, action).then(
            (response) => {
                console.log(response);
                setproductList(response.data.products);
                setpageNo(response.data.nextpage);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(resMessage);
            }
        );
    }

    useEffect(() => {
        fetchData(1);
    }, []);

    /* ================================Product checkbox handle section ==============================*/
    const handleCheckAllChange = (e) => {
        if (e.target.checked) {
            const allProducts = productList.map((c) => c._id);
            setChecked(allProducts);
        } else {
            setChecked([]);
        }
    };
    const handleProductChange = (e, c) => {
        if (e.target.checked) {
            setChecked([...checked, c._id]);
        } else {
            setChecked(checked.filter((item) => item !== c._id));
        }
    };

    const deleteSelected = () => {
        if (window.confirm('Are you sure you want to delete this selected data?')) {
            if (checked.length > 0) {
                UserService.deleteSpecificData(checked).then(
                    (response) => {
                        //  console.log(response);
                        window.location.reload();
                        setChecked([]);
                    },
                    (error) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        //  console.log(resMessage);
                    }
                );
            }
        }
    };

    /* ================================ Search product by Name ==============================*/

    const searchProductData = (e) => {
        var data = {
            "query": e.target.value
        };
        //  console.log(data);
        setsearchstr(e.target.value);
        UserService.searchProductData(data).then(
            (response) => {
                //console.log(response);
                setproductList(response.data.products);
                setpageNo(response.data.nextpage);
                if (response.data.products.length <= 0) {
                    seterrorMessage(true);
                } else {
                    seterrorMessage(false)
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(resMessage);
            }
        );
    };

    return (
        <>
            <div className="table-container" style={{ display: 'block' }}>
                <div className="row">
                    <div className="col">
                        <h5 className="pb-2 mb-2 mt-2">Products</h5>
                    </div>
                    <div className="col text-right">
                        {checked.length > 0 && (<button className="btn" onClick={deleteSelected}>Delete</button>)}
                        <input type="text" name="searchstr" onChange={searchProductData} value={searchstr} />
                    </div>
                </div>
                <div className="d-flex text-muted schedul-tbl-box table-responsive">
                    <table className="table table-bordered">
                        <thead className="table-dark">
                            <tr>
                                <th><input className="form-check-input" type="checkbox" id="selectAll" checked={checked.length === productList.length} onChange={handleCheckAllChange} /></th>
                                <th>Name</th>
                                <th>Customer</th>
                                <th>Image</th>
                                <th>Brand</th>
                                <th>Gender</th>
                                <th>Current Price</th>
                                <th>Original Price</th>
                                <th>In Club</th>
                                <th>Color</th>
                                <th>Men's Size</th>
                                <th>Women's Size</th>
                                <th>Kid's Size</th>
                                <th>Month Pulled</th>
                                <th>Year Pulled</th>
                                <th>Features</th>
                                <th>Content</th>
                                <th>Sizing</th>
                                <th>Care Instructions</th>
                                <th>URL</th>
                                <th>Type</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>

                            {productList && productList.length > 0 && productList.map((productListObj, index) => (
                                <tr key={productListObj._id}>
                                    <td><input type="checkbox" className="productid" id={productListObj._id} checked={checked.includes(productListObj._id)} onChange={(e) => handleProductChange(e, productListObj)} /></td>
                                    <td>{productListObj.name}</td>
                                    <td>{productListObj.customer}</td>
                                    <td><img src={productListObj.image} width={85} /></td>
                                    <td>{productListObj.brand}</td>
                                    <td>{productListObj.gender}</td>
                                    <td>{productListObj.currentPrice}</td>
                                    <td>{productListObj.originalPrice}</td>
                                    <td>{productListObj.inClub}</td>
                                    <td>{productListObj.color}</td>
                                    <td>{productListObj.menSize}</td>
                                    <td>{productListObj.womenSize}</td>
                                    <td>{productListObj.kidsSize}</td>
                                    <td>{productListObj.monthPulled}</td>
                                    <td>{productListObj.yearPulled}</td>
                                    <td>{productListObj.features}</td>
                                    <td>{productListObj.content}</td>
                                    <td>{productListObj.sizing}</td>
                                    <td>{productListObj.careInstructions}</td>
                                    <td>{productListObj.url}</td>
                                    <td>{productListObj.type}</td>
                                    <td>{format(new Date(productListObj.createdAt), 'Pp')}</td>
                                </tr>
                            ))}
                            {errorMessage && (<tr><td colSpan={3}>Product not found!</td></tr>)}
                        </tbody>
                    </table>
                </div>
                <div className="showmore-section d-flex"><button className="btn" onClick={e => fetchData(pageno, 'prev')}>Previous Page</button> <button className="btn" onClick={e => fetchData(pageno, 'next')}>Next Page</button></div>
            </div>
        </>
    );
};

export default adminLayout(ListProducts);
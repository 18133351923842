import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import "../../assets/css/profile.css"
import userProfileLayout from "../../hoc/userProfileLayout";
import UserService from "../../services/user.service";

const ProfilePage = () => {
    const navigate = useNavigate();

    const required = (value) => {
        if (!value) {
          return (
            <div className="invalid-feedback d-block">
              This field is required!
            </div>
          );
        }
    };
      
      
    const form = useRef();
    const checkBtn = useRef();

    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeFullname = (e) => {
        const fullname = e.target.value;
        setFullname(fullname);
    };  
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };   

    const fetchData = () => {
        UserService.getProfileData().then(
            (response) => {
                console.log(response);
                setFullname(response.data[0].fullname);
                setEmail(response.data[0].email);
            },
            (error) => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
    
            setLoading(false);
            console.log(resMessage);
            }
        );        
    }

    useEffect(() => {
        fetchData();
    },[]);       


    const handleUpdate = (e) => {
        e.preventDefault();
    
        setMessage("");
        setLoading(true);
    
        form.current.validateAll();
        const data = {
            fullname: fullname,
            email: email
        }

        if (checkBtn.current.context._errors.length === 0) {
            UserService.profileupdate(data).then(
                (response) => { 
                    setMessage(response.msg);
                    setLoading(false);
                    setFullname(response.data.fullname);
                    setEmail(response.data.email);
                    navigate("/profile");
                },
                (error) => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
        
                setLoading(false);
                setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    };

    return <>
            <div className="my-3 p-3 bg-body rounded shadow-sm">
                <h6 className="border-bottom pb-2 mb-0 mb-3">Personal Info</h6>
                    <Form className="login-form" onSubmit={handleUpdate} ref={form}>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="exampleInputEmail1" className="form-label">Full Name</label>
                                <div className="input-group mb-3">
                                    <Input type="text" name="fullname" className="form-control" placeholder="Full Name" value={fullname} onChange={onChangeFullname} validations={[required]} />
                                    <span className="input-group-text" id="basic-addon2"><i className="fa fa-user"></i></span>
                                </div>
                            </div>                                
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                <div className="input-group mb-3">
                                    <Input type="text" name="email" className="form-control" placeholder="Email Address" value={email} onChange={onChangeEmail} validations={[required]} />
                                    <span className="input-group-text" id="basic-addon2">@</span>
                                </div>
                            </div>
                        </div>   
                        
                        <div className="col-12">
                            <button className="btn btn-primary btn-lg" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span className="px-2">Save</span>
                            </button>   
                        </div>

                        {message && (
                            <div className="form-group mt-3">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
            </div>
        
    </>    
}

export default userProfileLayout(ProfilePage);
import React, { useState, useRef } from "react";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import adminLayout from "../../hoc/adminLayout";
import UserService from "../../services/user.service";

const CreateUser = () => {

    const required = (value) => {
        if (!value) {
            return (
                <div className="invalid-feedback d-block">
                    This field is required!
                </div>
            );
        }
    };

    const form = useRef();
    const checkBtn = useRef();

    const [fullname, setFullname] = useState("");
    const [emailid, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeName = (e) => {
        const fullname = e.target.value;
        setFullname(fullname);
    };

    const onChangeEmail = (e) => {
        const emailid = e.target.value;
        setEmail(emailid);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onValueChange = (e) => {
        const role = e.target.value;
        setRole(role);
    };

    const handleAddUser = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        form.current.validateAll();
        const data = {
            fullname: fullname,
            email: emailid,
            password: password,
            password_confirmation: password,
            role: role
        }

        if (checkBtn.current.context._errors.length === 0) {
            UserService.addnewuser(data).then(
                (response) => {
                    setMessage(response);
                    setLoading(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="my-5 p-3 bg-body rounded shadow-sm">
                <section id="forms">
                    <article className="my-3" id="sizing">
                        <div className="bd-heading sticky-xl-top align-self-start mt-4 mb-5 mt-xl-0 mb-xl-4">
                            <h3>Add User</h3>
                        </div>
                        <Form className="login-form" onSubmit={handleAddUser} ref={form}>
                            <div>
                                <div className="bd-example">
                                    <div className="mb-3 col-4">
                                        <label className="mb-3">Full Name</label>
                                        <Input className="form-control form-control-md" name="fullname" type="text" placeholder="Enter Name" aria-label=".form-control-lg" value={fullname} onChange={onChangeName} validations={[required]} />
                                    </div>
                                    <div className="mb-3 col-4">
                                        <label className="mb-3">Email Id</label>
                                        <Input className="form-control form-control-md" name="emailid" type="email" placeholder="Enter your Email" aria-label=".form-control-lg" value={emailid} onChange={onChangeEmail} validations={[required]} />
                                    </div>
                                    <div className="mb-3 col-4">
                                        <label className="mb-3">Password</label>
                                        <Input className="form-control form-control-md" name="password" type="text" placeholder="Enter Password" aria-label=".form-control-lg" value={password} onChange={onChangePassword} validations={[required]} />
                                    </div>
                                    <div className="mb-3 col-4">
                                        <label className="mb-3">Select Role</label>
                                        <div className="radio">
                                            <label>
                                                <input type="radio" value="admin" checked={role === "admin"} onChange={onValueChange} /> Admin
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input type="radio" value="staff" checked={role === "staff"} onChange={onValueChange} /> Staff
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary btn-md" disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Add User</span>
                                </button>
                            </div>

                            {message && (
                                <div className="form-group mt-4">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </article>
                </section>
            </div>
        </>
    );
}

export default adminLayout(CreateUser);